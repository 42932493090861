import { createRouter, createWebHistory } from "vue-router";
import Login from "../auth/Login.vue";
import Register from "../auth/Register.vue";
import Forgot from "../auth/ForgotPassword.vue";
import Home from "../pages/Home";
import AboutUs from "../pages/AboutUs.vue";
import Profile from "../pages/Profile.vue";
import Product from "../pages/Product.vue";
import Service from "../pages/Service.vue";
import BookingProduct from "../pages/BookingProduct.vue";
import DetailProductRoom from "../pages/DetailProductRoom.vue";
import BookingRoom from "../pages/BookingRoom.vue";
import VerifyOTP from "../auth/VerifyOTP.vue";
import ResetPassword from "../auth/ResetPassword.vue";
import SuccessPayment from "../pages/SuccessPayment.vue";
import Location from "../pages/Location.vue";
import WhatsOn from "../pages/WhatsOn.vue";
import WhatsOnDetail from "../pages/WhatsOnDetail.vue";
const routes = [
  {
    path: "/",
    component: Home,
    meta: { layout: "main-layout" },
  },
  {
    path: "/about-us",
    component: AboutUs,
    meta: { layout: "main-layout" },
  },
  {
    path: "/profile",
    component: Profile,
    meta: { layout: "main-layout" },
  },
  {
    name: "product",
    path: "/product",
    component: Product,
    meta: { layout: "main-layout" },
  },
  {
    name: "detail-product",
    path: "/detail-product",
    component: BookingProduct,
    meta: { layout: "main-layout" },
  },
  {
    name: "detail-product-room",
    path: "/detail-product-room",
    component: DetailProductRoom,
    meta: { layout: "main-layout" },
  },
  {
    name: "confirmation-payment",
    path: "/confirmation-payment",
    component: SuccessPayment,
    meta: { layout: "main-layout" },
  },
  {
    name: "service",
    path: "/service",
    component: Service,
    meta: { layout: "main-layout" },
  },
  {
    name: "location",
    path: "/location",
    component: Location,
    meta: { layout: "main-layout" },
  },
  {
    name: "whats-on",
    path: "/whats-on",
    component: WhatsOn,
    meta: { layout: "main-layout" },
  },
  {
    name: "whats-on-detail",
    path: "/whats-on-detail",
    component: WhatsOnDetail,
    meta: { layout: "main-layout" },
  },
  {
    name: "booking-room",
    path: "/booking-room",
    component: BookingRoom,
    meta: { layout: "main-layout" },
  },
  {
    path: "/login",
    component: Login,
    meta: { layout: "auth-layout" },
  },
  {
    path: "/register",
    component: Register,
    meta: { layout: "auth-layout" },
  },
  {
    path: "/forgot-password",
    component: Forgot,
    meta: { layout: "auth-layout" },
  },
  {
    name: "verify-otp",
    path: "/verify-otp",
    component: VerifyOTP,
    meta: { layout: "auth-layout" },
  },
  {
    name: "reset-password",
    path: "/reset-password",
    component: ResetPassword,
    meta: { layout: "auth-layout" },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const publicPages = [
    "/",
    "/about-us",
    "/login",
    "/register",
    "/forgot-password",
    "/service",
    "/product",
    "/detail-product",
    "/detail-product-room",
    "/booking-room",
    "/verify-otp",
    "/reset-password",
    "/confirmation-payment",
    "/location",
    "/whats-on",
    "/whats-on-detail"
  ];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");

  // trying to access a restricted page + not logged in
  // redirect to login page

  if (authRequired && !loggedIn) {
    next({
      path: "/login",
      query: {
        redirect: to.fullPath,
      },
    });
  } else {
    next();
  }
});
export default router;
