<template>
    <div class="mx-auto w-full p-8">
      
      <div wire:loading class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center" v-if="isLoading">
          <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
          <h2 class="text-center text-white text-xl font-semibold">Loading</h2>
          <p class="w-1/3 text-center text-white mt-2">This may take a few seconds, please don't close this page.</p>
      </div>
      <div v-else>
        <section class="md:mb-2 mx-auto w-full lg:p-20 xl:mt-0 lg:mt-0 md:mt-20 sm:mt-20">
          <div class="mt-5 grid xl:grid-cols-2 lg:xl:grid-cols-2 md:xl:grid-cols-2 sm:xl:grid-cols-1 gap-2 items-center lg:float-right">
            <select id="countries" class="
            bg-yellow-400 
            border 
            border-gray-300 
            text-gray-900 
            text-sm 
            rounded-lg 
            focus:ring-white-500 
            focus:border-white-500 
            block lg:w-[200px] md:w-full p-4"
            v-model="location_id"
            @change="findProduct(null,null,null)"
            >
            <option value="" disabled>Select Location</option>
                <option
                v-for="item in locations" 
                v-bind:value="item.id"
                v-bind:key="item.id"
            >
                {{ item.name }}
            </option>
              
            </select>
            <ul
            class="relative list-none rounded-lg bg-blue-gray-50/60 p-1 flex flex-nowrap overflow-x-auto space-x-4 scroll-smooth focus:scroll-auto snap-x"
            data-tabs="tabs"
            role="list"
           
          >
            <span  v-for="item in categories" :key="item.id">
              <li class="z-30 flex-auto text-center">
                <a
                v-bind:class="isTab == item.code ?
                 'z-30 mb-0 flex w-[168px] cursor-pointer items-center justify-center rounded-full border-0 px-2 py-2 transition-all ease-in-out bg-yellow-900 text-white snap-normal snap-center' : 
                 'text-white z-30 mb-0 flex w-[168px] cursor-pointer items-center justify-center rounded-full border-0  px-2 py-2 transition-all ease-in-out  bg-gray-400 snap-normal snap-center'"
                  data-tab-target=""
                  active
                  role="tab"
                  aria-selected="true"
                  @click="findProduct(item.code,item.id,null)"
                >
                  <span class="ml-1">{{item.name}}</span>
                </a>
              </li>
            </span>
          </ul>
            <!-- <select id="countries" 
            class="
            bg-white 
            border 
            border-gray-300 
            text-gray-900 
            text-sm 
            rounded-lg 
            focus:ring-white-500 
            focus:border-white-500 
            block lg:w-[200px] md:w-full p-4"
            v-model="category_id"
            @change="findProduct"
            >
                <option value="" disabled>Select Service</option>
                    <option
                    v-for="item in categories" 
                    v-bind:value="item.id"
                    v-bind:key="item.id"
                >
                    {{ item.name }}
                </option>
            </select> -->
          </div>
        </section>

        <section class="md:mt-20 lg:mt-0 xl:md-0 mx-auto w-full lg:p-20">
            <div v-if="isProductPrices == false">
              <div class="mt-10 grid gap-8  md:grid-cols-2 lg:gap-8 xl:grid-cols-4">

                <div  v-for="item in products" :key="item.id">
                  <div class="max-w-sm rounded overflow-hidden shadow-lg">
                    <img class="w-full " src="../assets/images/hero-content.png" alt="Sunset in the mountains"/>
                    <div class="px-2 py-4">
                        <div class="font-bold text-base text-black truncate">{{item.name}}</div>
                        <p class="text-gray-700 text-sm" v-if="item.has_room == 0">
                        Rp. {{ formatPrice(item.price) }}
                        </p>
                    </div>
                  
                    <div class="px-2 pt-10 pb-5 mt-8">
                        <div class="flex items-center float-right mb-2">
                            <button class="bg-red-700 hover:bg-700 text-white font-normal py-2 px-4 rounded-full text-sm mr-1 mb-2" @click="pushRoute(item.id,item.has_room)">
                                Lihat Detail
                            </button>
                        </div>
                        
                    </div>
                  
                  </div>
                </div>
                <div>

                </div>
              

                
            </div>
            <div class="max-w-lg mx-auto my-12" v-if="products.length > 0">
              <pagination :total-pages="totalPages" :total="total" :per-page="perPage" :current-page="currentPage"
                :has-more-pages="hasMorePages" @pagechanged="showMore">
              </pagination>
            </div>
            </div>
            <div v-else>
              <div class="mt-  grid gap-8 md:grid-cols-2 lg:gap-8 xl:grid-cols-4 ">

                <div  v-for="(detail, index) in paginated" :key="index">
                  <div class="max-w-sm rounded overflow-hidden shadow-lg">
                    <img class="w-full " src="../assets/images/hero-content.png" alt="Sunset in the mountains"/>
                    <div class="px-2 py-4">
                        <div class="font-bold text-base text-black truncate">{{detail.item}}</div>
                        <div class="font-bold text-base text-black truncate">{{detail.name}}</div>
                        <p class="text-gray-700 text-sm" >
                        Rp. {{ formatPrice(detail.price) }}
                        </p>
                    </div>
                    
                    <div class="px-2 pt-10 pb-5 mt-8">
                        <div class="flex items-center float-right mb-2">
                            <button class="bg-red-700 hover:bg-700 text-white font-normal py-2 px-4 rounded-full text-sm mr-1 mb-2" @click="bookRoom(detail.id)">
                                Lihat Detail
                            </button>
                        </div>
                        
                    </div>
                    </div>
                  
                </div>
                

                
            </div>
            <div class="max-w-lg mx-auto my-12"  v-if="paginated.length > 0">
              <pagination :total-pages="totalPages" :total="total" :per-page="perPage" :current-page="currentPage"
                :has-more-pages="hasMorePages" @pagechanged="showMoreRoom">
              </pagination>
            </div>
            </div>
        </section>
       
      </div>
    </div>
   
</template>
<script>
import AuthService from "@/services/auth.service";
import ApiService from '@/services/api.service';
import productService from "@/services/product.service";
import Pagination from "@/components/Pagination";
import { useRoute } from 'vue-router';
export default {
  name: "product-page",
  components: {
    Pagination
  },
  data() {
    return {
        isLoading: true,
        isProductPrices:false,
        tenant_id: "",
        page: 1,
        totalPages: 10,
        total: 10,
        perPage: 8,
        currentPage: 1,
        hasMorePages: true,
        location_id:'',
        category_id:'',
        locations: [],
        categories: [],
        products: [],
        product: {},
        productPrices:[],
        isTab: null,
        
    };
  },
  computed: {
    indexStart() {
      return (this.currentPage - 1) * this.perPage;
    },
    indexEnd() {
      return this.indexStart + this.perPage;
    },
    paginated() {
      
      return this.productPrices.slice(this.indexStart, this.indexEnd);
    },
  },
  async mounted() {
    
    
    const route = useRoute();  
    let router = await route;

    var data = router.query;
    if(data){
      
      if(data.location_id){
          this.location_id = data.location_id;
        
      
      }

      if(data.category_id){
        this.category_id = data.category_id;

      }

      AuthService.getDataTenant().then(
        (response) => {
          var data = response.data;
          this.tenant_id = data.id;
          this.getLocation(this.tenant_id);
          this.getCategories(this.tenant_id);
          
          
          
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
      
    
    }
    
   
   


    
  },
  methods:{
    getLocation(tenantId){
        ApiService.getDataLocation(tenantId).then(
        (response) => {
            var data = response.data;
            this.locations = data;
            if(this.location_id == ''){
              this.location_id = this.locations[0].id;
            }
           
        },
        (error) => {
            this.content =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
        }
        )
    },
    getCategories(tenantId){
      productService.getDataProductCategories(tenantId).then(
        (response) => {
          var data = response.data.data;
          this.categories = data;
          if(this.category_id == ''){
            this.category_id = this.categories[0].id;
          }
         
          this.getProduct();
         
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    getProduct(){
      
      productService.getDataProducts(this.tenant_id,this.category_id,this.location_id,this.page).then(
        (response) => {
          var data = response.data.data;
          this.products = data.data;

          this.currentPage = data.current_page;
          this.total = data.total;
          this.totalPages = data.last_page;
          this.last_page = data.last_page;
          this.isLoading = false;
          this.isProductPrices = false;
          
          // if(data.data.length > 1)
          // {
          //   this.products = data.data;

          //   this.currentPage = data.current_page;
          //   this.total = data.total;
          //   this.totalPages = data.last_page;
          //   this.last_page = data.last_page;
          //   this.isLoading = false;
          //   this.isProductPrices = false;
          // }else{
          //   this.getRoom(this.tenant_id,data.data[0].id);
          // }
          
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    getRoom(tenantId,productId){
      productService.getDataProductsById(tenantId,productId).then(
        (response) => {
          var data = response.data.data;
        
          this.product = data;
          if(this.location_id != null){
            var rooms = data.product_prices;
            this.productPrices.splice(0);
            for (let i = 0; i < rooms.length; i++) {
              if (data.has_room == 1) {
                if (this.location_id == rooms[i].room.location_id) {
                    this.productPrices.push(
                      {
                      "id": rooms[i].id,
                      "tenant_id" : rooms[i].tenant_id,
                      "product_id" : rooms[i].product_id,
                      "asset_type_id" : rooms[i].asset_type_id,
                      "room_id" : rooms[i].room_id,
                      "area_id" : rooms[i].area_id,
                      "has_quantity" : rooms[i].has_quantity,
                      "has_term" : rooms[i].has_term,
                      "has_complimentary": rooms[i].has_complimentary,
                      "term": rooms[i].term,
                      "item": rooms[i].item,
                      "name": rooms[i].name,
                      "currency_code": rooms[i].currency_code,
                      "default_quantity": rooms[i].default_quantity,
                      "price": rooms[i].price,
                      "desc": rooms[i].desc,
                      }
                    );
                  }
              }else{
                this.productPrices.push(
                      {
                      "id": rooms[i].id,
                      "tenant_id" : rooms[i].tenant_id,
                      "product_id" : rooms[i].product_id,
                      "asset_type_id" : rooms[i].asset_type_id,
                      "room_id" : rooms[i].room_id,
                      "area_id" : rooms[i].area_id,
                      "has_quantity" : rooms[i].has_quantity,
                      "has_term" : rooms[i].has_term,
                      "has_complimentary": rooms[i].has_complimentary,
                      "term": rooms[i].term,
                      "item": rooms[i].item,
                      "name": rooms[i].name,
                      "currency_code": rooms[i].currency_code,
                      "default_quantity": rooms[i].default_quantity,
                      "price": rooms[i].price,
                      "desc": rooms[i].desc,
                      }
                    );
              }
              
              
              }
              this.totalPages  = this.productPrices.length / 8;
              this.total = this.productPrices.length;
            
          }else{
            this.productPrices = data.product_prices;
            this.totalPages  = this.productPrices.length / 8;
              this.total = this.productPrices.length;
          }
          this.isProductPrices = true;
          this.isLoading = false;
          
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    findProduct(code = null, categoryId=null,locationId=null){

      this.isTab = code;
      this.category_id = categoryId;
      if(locationId != null){
        this.location_id = locationId;
      }
      
      productService.getDataProducts(this.tenant_id,this.category_id,this.location_id,this.page).then(
      (response) => {
        var data = response.data.data;
        this.isLoading = true;
        this.products = data.data;

        this.currentPage = data.current_page;
        this.total = data.total;
        this.totalPages = data.last_page;
        this.last_page = data.last_page;
        this.isLoading = false;
        this.isProductPrices = false;
        // if(data.data.length > 1)
        // {
        //   this.products = data.data;

        //   this.currentPage = data.current_page;
        //   this.total = data.total;
        //   this.totalPages = data.last_page;
        //   this.last_page = data.last_page;
        //   this.isLoading = false;
        //   this.isProductPrices = false;
        // }else{
        //   this.getRoom(this.tenant_id,data.data[0].id);
        // }
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
    },
    showMore(page){
      this.page = page;
      this.currentPage = page;

      productService.getDataProducts(this.tenant_id,this.category_id,this.location_id,this.page).then(
      (response) => {
        var data = response.data.data;
        this.products = data.data;
        this.currentPage = data.current_page;
        this.total = data.total;
        this.totalPages = data.last_page;
        this.last_page = data.last_page;
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
    },
    showMoreRoom(page){
      this.page = page;
      this.currentPage = page;
    },
    pushRoute(Id, has_room){
      if(Id != null){
        this.$router.push({
          name: "detail-product",
          query: {
            location_id: this.location_id,
            product_id: Id
          }
        }).then((response) => {
          location.reload();
        });
        
        
      }

    },
    bookRoom(id){
      var dataRoom = this.productPrices.find(room => room.id === id);
     
      this.$router.push({
        name: "booking-room",
        query: {
          location_id: this.location_id,
          product_id: dataRoom.product_id,
          selectedProductPrices: id,
        }
      }).then((response) => {
        location.reload();
      });
    }
  }
};
</script>