<template>
  <div class="mx-auto w-full p-8">
    <div wire:loading class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center" v-if="isLoadingPage">
        <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
        <h2 class="text-center text-white text-xl font-semibold">Loading</h2>
        <p class="w-1/3 text-center text-white mt-2">This may take a few seconds, please don't close this page.</p>
    </div>
    <div v-else>
      <div wire:loading class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center" v-if="isLoading">
          <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
          <h2 class="text-center text-white text-xl font-semibold">Loading...</h2>
          <p class="w-1/3 text-center text-white">This may take a few seconds, please don't close this page.</p>
      </div>
      
      <div class="mx-auto max-w-2xl px-4 pb-16 pt-10 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3  lg:gap-x-8 lg:px-8 lg:pb-24 lg:pt-16 mb-10 mt-10 gap-4">
        <div class=" bg-neutral-100 bg-white border border-neutral animate-fade-up animate-duration-1000 animate-delay-500  lg:col-span-1 lg:col-start-1 h-[500px]">
          <div v-if="product.picture_path != null">
            <img class="h-[350px] w-full rounded-t" :src="webUrl+product.picture_path" alt=""> 
          </div>
          <div v-else>
            <img class="h-[350px] w-full rounded-t" src="../assets/images/hero-content.png" alt="">
          </div>

          <div class="mt-2 p-4">
            <div class="font-bold text-md font-paragraph truncate">{{product.item ? product.item : product.name}}</div>
            <div class="mt-2 font-bold text-sm font-paragraph truncate">Rp {{ product.price ? formatPrice(product.price) : 0 }} / {{product.term}}</div>
            <div class="mt-2 font-bold text-sm font-paragraph truncate">{{location.name}}</div>
          </div>
    
        </div>
        <div class="bg-neutral-100 bg-white border border-neutral p-4 lg:col-span-2 ">
          <p class="font-paragraph tracking-tight font-display text-1xl font-bold">Form Pemesanan</p>
          <div class="mt-2 grid xl:grid-cols-2 lg:grid-cols-2  md:grid-cols-2 sm:grid-cols-1 gap-4 items-center">
            <div>
                <label for="helper-radio" class="block mb-2 font-normal dark:text-black">Mulai Tanggal</label>
                <div v-if="isRoom == false">
                    <input   datepicker :type="term == 'year' || term == 'month' || term == 'no term' ? 'date' : 'datetime-local'" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Select date" v-model="form.started_at" @change="setTime($event);changeDate(term,'ended_at')">
                </div>
                <div v-else>
                    <input  datepicker :type="term == 'year' || term == 'month' || term == 'no term' ? 'date' : 'datetime-local'" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Select date" v-model="form.started_at" @change="setTime($event);changeDate(term,'ended_at');getAvailRoom(productId,location_id,form.started_at,form.ended_at,term,form.tenant_id )">
                </div>
              </div>
              <div>
                <label for="helper-radio" class="block mb-2 font-normal dark:text-black">Jangka Waktu ({{term == 'hour' ? 'Jam' : term == 'year' ? 'Tahun' : term == 'day' ? 'Hari' : term == 'full day' ? 'Satu Hari' : term == 'half day' ? 'Setengah Hari' : 'Bulan'}})</label>
                <div v-if="product.has_room == 1">
                  <input v-model="form.length_of_term" type="number" min="1" 
                  @input="() => { 
                    if(form.length_of_term > max_length || form.length_of_term < 0) 
                    { 
                      form.length_of_term = max_length
                    }
                  }"
                  class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Jangka waktu" @change="changeDate(term,'ended_at');countPrice();" :disabled='product.term == "half day" || product.term == "full day" ? true : false'>
                </div>
                <div v-else>
                  <input v-model="form.length_of_term" type="number" min="1" 
                  @input="() => { 
                    if(form.length_of_term > max_length || form.length_of_term < 0) 
                    { 
                      form.length_of_term = max_length
                    }
                  }"
                  class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-5 p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Jangka waktu" @change="changeDate(term,'ended_at');countPrice();" :disabled='product.term == "half day" || product.term == "full day" ? true : false'>
                </div>
            </div>
          </div>
          <div class="mt-2">
            <div class="rounded border border-neutral p-2 bg-gray-300">
              <p class="text-black font-display text-1xl font-base">Detail Pembayaran</p>
            </div>
          </div>
          <div class="mt-2">
            <div class="border border-slate-700 p-2 grid md:grid-cols-2  gap-4 ">
              <div class="font-base text-md text-black truncate">{{product.item ? product.item : product.name}}</div>
              <div class="font-base text-md text-black truncate">Rp {{ product.price ? formatPrice(product.price) : 0 }} / {{product.term}}</div>
            </div>
            <div class="border border-slate-700 p-2 grid md:grid-cols-2  gap-4 ">
              <div class="font-base text-md text-black truncate">Sub Total</div>
              <div class="font-base text-md text-black truncate">Rp {{ formatPrice(total_price) }}</div>
            </div>
            <div class="border border-slate-700 p-2 grid md:grid-cols-2  gap-4 ">
              <div class="font-base text-md text-black truncate">Tax</div>
              <div class="font-base text-md text-black truncate">Rp {{ formatPrice(total_price) }}</div>
            </div>

          
          </div>
          <div class="mt-2">
            <label for="helper-radio" class="block mb-2 font-normal dark:text-black">Kode Promo</label>
            <div class="relative mb-2 flex flex-wrap items-stretch">
              <input
              v-model="form.promotion_code"
                type="text"
                class="relative m-0 block w-[400px] flex-auto bg-white border border-gray-300 text-gray-900 text-sm  rounded-l focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Kode Promo"
                aria-label="Kode Promo"
                aria-describedby="basic-addon2" />
                <button class="flex items-center whitespace-nowrap rounded-r  bg-red-700 hover:bg-700 text-white font-normal px-3 py-[0.25rem]  leading-[1.6] text-md "
                @click="getPromotionCode(form.promotion_code)"
                >
                  Gunakan Kode
              </button>
              
            </div>
            <button class="flex items-center whitespace-nowrap rounded-r  bg-neutral-700 hover:bg-700 text-white font-normal px-3 py-[0.25rem]  leading-[1.6] text-md "
              @click="clearPromotionCode()"
              >
                Hapus Kode
              </button>
          </div>
          <div class="mt-5">
            <div class="border border-slate-700 p-2 grid md:grid-cols-2  gap-4 bg-gray-300">
              <div class="font-base text-md text-black truncate">Total Pembayaran</div>
              <div class="font-base text-md text-black truncate">Rp {{ formatPrice(grand_total) }}</div>
            </div>
           
          </div>
          <div class="mt-2">
            <button class="bg-red-700 hover:bg-700 text-white font-normal py-3 px-2 rounded text-md mr-1 mb-2 w-full" @click="addProduct" :disabled="isDisable" :class="isDisable == true ? 'bg-gray-300' : ''">
              Book Now
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.loader {
border-top-color: #3498db;
-webkit-animation: spinner 1.5s linear infinite;
animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
0% {
  -webkit-transform: rotate(0deg);
}
100% {
  -webkit-transform: rotate(360deg);
}
}

@keyframes spinner {
0% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(360deg);
}
}

</style>
<style>
.loader {
border-top-color: #3498db;
-webkit-animation: spinner 1.5s linear infinite;
animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
0% {
  -webkit-transform: rotate(0deg);
}
100% {
  -webkit-transform: rotate(360deg);
}
}

@keyframes spinner {
0% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(360deg);
}
}

</style>
<script>
import AuthService from "@/services/auth.service";
import productService from "@/services/product.service";
import { useRoute } from 'vue-router';
import moment from 'moment';
import roomService from "@/services/room.service";
import Swal from "sweetalert2";
import{ webUrl } from '../utils/config';
export default {
name: "product-room-page",
data() {
  return {
      isAvail: false,
      isLoading:false,
      isLoadingPage:false,
      isLoadingCheck: false,
      isRoom: false,
      isDisable: false,
      webUrl: webUrl,
      tenant:{},
      location_id:'',
      productId:'',
      selectedProductPricesId:'',
      product: {},
      selectedProductPrices:{},
      productPrices:[],
      productIncludes:[],
      includeSalesOrders:[],
      location:{},
      room:{},
      tax:0,
      service_charge:0,
      total_cost:0,
      total_price:0,
      grand_total:0,
      quantity:1,
      half_day_length:4,
      full_day_length:8,
      depreciation_perice:0,
      isDisabled: true,
      start_time:'',
      end_time:'',
      discount: 0,
      total_use_of_complimentary:0,
      max_length: 12,
      form:{
          checkout_with_xendit : "Y",
          tenant_id : 1,
          location_id : 1,
          customer_id : '',
          contact_id : '',
          emergency_contact_id : null,
          primary_product_id : 1,
          code :'',
          is_inquiry : false,
          has_contract : false,
          is_renewal : false,
          status : "posted",
          renewal_status : "on renewal",
          started_at : '',
          ended_at : '',
          signed_at : '',
          term : "month",
          term_of_payment : 'annually',
          term_notice_period : 3,
          tax_percentage : 11,
          length_of_term : 12,
          deposit:0,
          total_cost : 0,
          total_price : 0,
          total_discount : 0,
          total_tax : 0,
          currency_code : "IDR",
          sales_order_details:[],
          reference_code:'',
          promotion_code:''
      }
      
  };
},
computed: {
  currentUser() {
    return this.$store.state.auth.user;
  },
},
async mounted() {
  this.getNow();

  AuthService.getDataTenant().then(
      (response) => {
      var data = response.data;
      this.tenant = data;
      this.form.tenant_id =  data.id;
      this.getLocation(data.id, this.location_id);
      this.half_day_length = data.half_day_length;
      this.full_day_length = data.full_day_length;
      
      
      
      },
      (error) => {
      this.content =
          (error.response &&
          error.response.data &&
          error.response.data.message) ||
          error.message ||
          error.toString();
      }
  );


  if(this.currentUser){
      roomService.getCodeSalesOrder().then(
          (response) => {
          var data = response.data;
          this.form.code = data.data;
          
          
          },
          (error) => {
          this.content =
              (error.response &&
              error.response.data &&
              error.response.data.message) ||
              error.message ||
              error.toString();
          }
      );
      this.form.customer_id =  this.currentUser.user.person.customer.id;
      this.form.contact_id = this.currentUser.user.person.customer.id;
  }
  
  const route = useRoute();  
  let router = await route;

  var data = router.query;
  if(data){
      this.location_id = data.location_id;
      this.productId = data.product_id;
     
      this.form.location_id = this.location_id;
      
      if(data.promotion_code != null){
        sessionStorage.setItem('promotion_code',data.promotion_code);
      }

      if(data.reference_code != null){
        sessionStorage.setItem('reference_code',data.reference_code);
      }
      
     

      AuthService.getDataTenant().then(
      (response) => {
        var data = response.data;
        this.tenant = data;
        this.getLocation(data.id, this.location_id);
       
        
        
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );

     
  }
  const promotion_code = sessionStorage.getItem("promotion_code");
  const reference_code = sessionStorage.getItem("reference_code");
  
  if(reference_code != null){
    this.form.reference_code = reference_code;
  }

  if(promotion_code != null){
    this.form.promotion_code = promotion_code;
  }

  
},
methods:{
  getNow() {
    var dateTime = '';
    const today = new Date();
    const time = today.getHours() + ":00";



    if(this.term == 'year' || this.term == 'month' || this.term == 'no term'){
      dateTime = moment().format('YYYY-MM-DD');
    }else{
      dateTime = moment().format('YYYY-MM-DD')+'T'+moment(time, "HH:mm:ss").format('HH:mm');
      
    }
    this.date_now = dateTime;
    this.form.started_at = dateTime;
    this.form.length_of_term = 1;
    this.changeDate(this.term,'ended_at');
      

  },
  formatPrice(value) {
    let val = (value / 1).toFixed(2).replace(".", ",");
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  },
  async getLocation(tenantId, locationId){
      productService.getDataLocationById(tenantId,locationId).then(
      (response) => {
          var data = response.data.data;
          this.location = data;
          this.getProduct(this.tenant.id, this.productId);
      },
      (error) => {
          this.content =
          (error.response &&
              error.response.data &&
              error.response.data.message) ||
          error.message ||
          error.toString();
      }
      );
  },
  async getProduct(tenantId, productId){
      productService.getDataProductsById(tenantId,productId).then(
      (response) => {
        var data = response.data.data;
        this.term = data.term;
        this.form.term = data.term;
        this.start_time = data.started_time_at;
        this.end_time = data.ended_time_at;
        if(data.product_prices.length > 0)
        {
          this.isRoom = true;
          this.isLoadingPage = false;
        }else{
          this.isRoom = false;
          this.product = data;
          this.countPrice();
          this.isLoadingPage = false;
        }

        switch(this.term){
          case "hour":
            this.max_length = 12;
            break;
          case "day":
            this.max_length = 30;
            break;
          case "week":
              this.max_length = 54;
              break;
          case "month":
              this.max_length = 60;
              break;
          case "year":
              this.max_length =5;
              break;
        }
          
        
        this.getNow();
       
      
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
  changeDate(term,targetIdChange){
    if(this.term == 'year' || this.term == 'month' || this.term == 'no term'){
      this.autoSetupDateTime(this.form.started_at, targetIdChange, term, this.form.length_of_term, true, 'date', false);
    }else{
      this.autoSetupDateTime(this.form.started_at, targetIdChange, term, this.form.length_of_term, true, 'datetime', false);
    }
     
  },
  autoSetupDateTime(sourceDate, targetIdChange, term, lengthOfTerm, isAddition=true, format='date', isReturn = false)
  {
  
   
    var currentDate = new Date(sourceDate);

    var targetDate = new Date(sourceDate);

    var factor = 1;

    lengthOfTerm = parseInt(lengthOfTerm)

    switch (term) {
      case 'full day':
          factor = this.full_day_length * 60 * 60 * 1000;

          targetDate = (isAddition) ? new Date(parseInt(currentDate.getTime() + lengthOfTerm * factor)) : new Date(parseInt(currentDate.getTime() - lengthOfTerm * factor));
          break;
      case 'half day':
          factor = this.half_day_length * 60 * 60 * 1000;

          targetDate = (isAddition) ? new Date(parseInt(currentDate.getTime() + lengthOfTerm * factor)) : new Date(parseInt(currentDate.getTime() - lengthOfTerm * factor));
          break;
      case 'hour':
          factor = 60 * 60 * 1000;

          targetDate = (isAddition) ? new Date(parseInt(currentDate.getTime() + lengthOfTerm * factor)) : new Date(parseInt(currentDate.getTime() - lengthOfTerm * factor));
          break;
      case 'day':
          factor = 24 * 60 * 60 * 1000;

          targetDate = (isAddition) ? new Date(parseInt(currentDate.getTime() + lengthOfTerm * factor)) : new Date(parseInt(currentDate.getTime() - lengthOfTerm * factor));
          break;
      case 'month':
          var newYear = parseInt(currentDate.getFullYear());
          var newMonth = parseInt(currentDate.getMonth());
          var newDate = currentDate.getDate();

          if (isAddition) {
              newMonth = newMonth + lengthOfTerm;

              if (newMonth > 12) {
                  newYear = newYear + (Math.floor(newMonth / 12));
                  newMonth = newMonth % 12;
              }
          } else {
              newMonth = newMonth - lengthOfTerm;

              if (newMonth < 0) {
                  newMonth = newMonth * -1;

                  newYear = newYear - (Math.floor(newMonth / 12));
                  newMonth = newMonth % 12;
              }
          }

          newDate = (newDate > 30 && (newMonth + 1) % 2 === 0) ? 30 : newDate;

          if (newDate > 28 && (newMonth + 1) == 2) {
              newDate = (newYear % 4 === 0) ? 29 : 28;
          }

          targetDate = new Date(newYear, newMonth, newDate);

          if (!isReturn) {
              factor = 24 * 60 * 60 * 1000;

              targetDate = (isAddition) ? new Date(parseInt(targetDate.getTime() - 1 * factor)) : new Date(parseInt(targetDate.getTime() + 1 * factor));
          }

          break;
      case 'year':
          targetDate = new Date((isAddition) ? parseInt(currentDate.getFullYear()) + lengthOfTerm : parseInt(currentDate.getFullYear()) - lengthOfTerm, parseInt(currentDate.getMonth()), currentDate.getDate());

          if (!isReturn) {
              factor = 24 * 60 * 60 * 1000;

              targetDate = (isAddition) ? new Date(parseInt(targetDate.getTime() - 1 * factor)) : new Date(parseInt(targetDate.getTime() + 1 * factor));
          }

          break;
      case 'no term':
          factor = 0;

          targetDate = (isAddition) ? new Date(parseInt(currentDate.getTime() + lengthOfTerm * factor)) : new Date(parseInt(currentDate.getTime() - lengthOfTerm * factor));
          break;
    }

    if (term != 'custom') {
        var yearFormat = targetDate.getFullYear();
        var monthFormat = (targetDate.getMonth() < 9) ? '0'+ parseInt(targetDate.getMonth() + 1) : parseInt(targetDate.getMonth() + 1);
        var dateFormat = (targetDate.getDate() < 10) ? '0'+targetDate.getDate() : targetDate.getDate();
        var hourFormat = (targetDate.getHours() < 10) ? '0'+targetDate.getHours() : targetDate.getHours();
        var minuteFormat = (targetDate.getMinutes() < 10) ? '0'+targetDate.getMinutes() : targetDate.getMinutes()

        if (format == 'date') {
            if (isReturn) {
                this.form.ended_at = yearFormat+'-'+monthFormat+'-'+dateFormat;
            } else {
                if(this.targetIdChange == 'start'){
                    this.form.started_at =yearFormat+'-'+monthFormat+'-'+dateFormat;
                }else{
                  
                    this.form.ended_at =yearFormat+'-'+monthFormat+'-'+dateFormat;
                }
              
            }
            
        } else {
            if (isReturn) {
                this.form.ended_at = yearFormat+'-'+monthFormat+'-'+dateFormat+'T'+hourFormat+':'+minuteFormat;
            } else {
                if(this.targetIdChange == 'start'){
                    this.form.started_at = yearFormat+'-'+monthFormat+'-'+dateFormat+'T'+hourFormat+':'+minuteFormat;
                }else{
                    this.form.ended_at = yearFormat+'-'+monthFormat+'-'+dateFormat+'T'+hourFormat+':'+minuteFormat;
                }
            }
        } 

        
    }
    
    if(this.isRoom ==  true){
        this.getAvailRoom(this.productId,this.location_id,this.form.started_at,this.form.ended_at,this.term, this.tenant.id);
    }
      
     
  },
  countPrice(promotion = null){
      
    var price = 0;

    var service_charge = 0;

    var tax =  0;


    var quantity = this.quantity;

    var isCalculate = false;

    var length_of_term = this.form.length_of_term;

    if (this.total_use_of_complimentary > length_of_term) {
      this.total_use_of_complimentary = this.form.length_of_term;
    }


    price =  this.product.price * quantity * (length_of_term - this.total_use_of_complimentary);


    tax = (parseFloat(price) - parseFloat(this.discount) + parseFloat(this.service_charge)) * this.tenant.tax_percentage / 100;

    if (promotion != null && this.discount == 0) {
        if (promotion.has_product == 1) {
            for (let i = 0; i < promotion.products.length; i++) {
              if (promotion.products[i].id == this.productId) {
                  isCalculate = true;
                  break;
              } 
              
            }
          
        } else {
            isCalculate = true;
        }
      
        if (isCalculate) {
            if(promotion.type == 'percentage') {
                this.discount = parseFloat(price) * parseFloat(promotion.total) / 100;
              

                service_charge -= parseFloat(this.service_charge) * parseFloat(promotion.total) / 100;

                tax -= parseFloat(tax) * parseFloat(promotion.total) / 100;
            } else {
              this.discount = promotion.total;
            }
        } else {
            this.discount = 0;

            this.service_charge = parseFloat(price) * this.tenant.service_charge_percentage / 100;
            tax = (parseFloat(price) - parseFloat(this.discount) + parseFloat(this.service_charge)) * this.tenant.tax_percentage / 100;
        }

      
    }else{
      this.discount = 0;
      if (this.tenant.service_charge_percentage > 0 && service_charge > 0) {
          this.service_charge = (parseFloat(price) - parseFloat(this.discount)) * this.tenant.service_charge_percentage / 100;
          tax = (service_charge * 100) / 100;
      }

      tax = (parseFloat(price) - parseFloat(this.discount) + parseFloat(this.service_charge)) * this.tenant.tax_percentage / 100;
    
    }




    var total_price = (parseFloat(price) + parseFloat(this.service_charge));
    var grand_total = (parseFloat(price) - parseFloat(this.discount) + parseFloat(this.service_charge) + parseFloat(tax));



    this.total_price = price;
    this.form.total_price = total_price;
    this.tax = tax;
    this.form.total_tax = tax;
    this.grand_total = grand_total;
    this.form.total_discount =  this.discount;
  },
  getAvailRoom(productId,locationId,startedAt,endedAt,term,tenantId){
      productService.getAvailRoom(productId,locationId,startedAt,endedAt,term,tenantId).then(
      (response) => {
         var data = response.data.data;
         if(data != null)
         {
          if(data.length > 0){
            this.product = data[0];
            this.countPrice();
            this.isDisable = false;
            
          }else{
            this.isDisable = true;
            this.product = {};
            this.total_price = 0;
            this.form.total_price = 0;
            this.tax = 0;
            this.form.total_tax = 0;
            this.grand_total = 0;
            Swal.fire({
                text: "Ruangan tidak tersedia untuk rentang waktu tersebut, mohon menganti rentang waktu pesanan anda.",
                icon: "error",
                customClass: {
                confirmButton: "btn fw-bold btn-danger",
                },
            });
          } 
        }else{
          this.isDisable = true;
          this.product = {};
          this.total_price = 0;
          this.form.total_price = 0;
          this.tax = 0;
          this.form.total_tax = 0;
          this.grand_total = 0;
          Swal.fire({
              text: "Ruangan tidak tersedia untuk rentang waktu tersebut, mohon menganti rentang waktu pesanan anda.",
              icon: "error",
              customClass: {
              confirmButton: "btn fw-bold btn-danger",
              },
          });
        } 
        
        
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
  addProduct(){
      if(this.currentUser != null){
        const promotion_code = sessionStorage.getItem("promotion_code");
        const reference_code = sessionStorage.getItem("reference_code");
        
        if(reference_code != null){
          this.form.reference_code = reference_code;
        }

        if(promotion_code != null){
          this.form.promotion_code = promotion_code;
        }

          this.form.sales_order_details.splice(0);
          this.includeSalesOrders.splice(0);
          this.form.primary_product_id = this.productId;
          this.form.signed_at = this.form.started_at;
          
          this.form.currency_code = this.product.currency_code;


          this.form.started_at = this.form.term == 'year' || this.form.term == 'month'  ? this.form.started_at+' '+"00:00:00" :  this.form.started_at;
          this.form.ended_at = this.form.term == 'year' || this.form.term == 'month'  ? this.form.ended_at+' '+"00:00:00" :  this.form.ended_at;

          if(this.form.term == 'hour' || this.form.term == 'half day' || this.form.term == 'full day'){
            this.form.term = 'day';
          }

          
          this.form.sales_order_details.push({
              "product_id" : this.productId,
              "vendor_id": null,
              "location_id": null,
              "complimentary_id" : "",
              "customer_complimentary_id" : "",
              "asset_type_id" : null,
              "asset_id" :null,
              "room_id" : this.product.room ? this.product.room.id : null,
              "name" : this.product.item ? this.product.item : this.product.name,
              "type" : "charged",
              "has_complimentary" :false,
              "has_term" : this.product.has_term,
              "is_repeated_in_term" :false,
              "term" : this.term,
              "repeated_term" : "no term",
              "started_at" : this.form.started_at,
              "ended_at" : this.form.ended_at,
              "length_of_term" : this.form.length_of_term,
              "has_quantity" : false,
              "quantity" : 1,
              "total_use_of_complimentary" : 0,
              "cost" : 0,
              "price" : this.product.price,
              "discount" : this.discount,
              "service_charge" : this.service_charge,
              "tax" : this.tax / this.form.length_of_term,
              
          });

         

          // console.log(this.form);
          this.bookProduct();
      }else{
          Swal.fire({
              text: "Anda harus login terlebih dahulu.",
              icon: "error",
              customClass: {
              confirmButton: "btn fw-bold btn-danger",
              },
          }).then((result) =>{
          if (result.isConfirmed) {
              this.$router.push({
                path: '/login',
                query: {
                  booking: true,
                },
              });
            }
        });
      }
  },
  bookProduct(){
     
      this.isLoading = true;
      productService.booking(this.form).then(
      (response) => {
          var data = response;
          this.isAvail =  true;
          this.isLoading = false;
          Swal.fire({
              text: "Sukses Booking, Segera lakukan pembayaran.",
              icon: "success",
              customClass: {
              confirmButton: "btn fw-bold btn-danger",
              },
          }).then((result) =>{
          if (result.isConfirmed) {
              this.$router.push({
                path: '/profile',
              });
              window.open(data.xendit_invoice_url, '_blank');
            }else{
              this.$router.push({
                path: '/profile',
              });
              window.open(data.xendit_invoice_url, '_blank');
            }
        });
      },
      (error) => {
          
          this.isAvail = false;
          this.isLoading = false;
         Swal.fire({
              text: "Gagal booking, mohon dicoba kembali.",
              icon: "error",
              customClass: {
              confirmButton: "btn fw-bold btn-danger",
              },
          });
      }
      );
  },
  getPromotionCode(code){
    if(code){
      if(this.currentUser)
      {
         
      if (this.form.total_price == 0) {
        Swal.fire({
            text: "Anda tidak bisa menggunakan kode promo, dikarenakan harga total sudah 0.",
            icon: "error",
            customClass: {
            confirmButton: "btn fw-bold btn-danger",
            },
        });
      }else{
        productService.getPromotionCode(code,this.form.customer_id,this.productId,this.form.length_of_term,this.quantity).then(
        (response) => {
            var data = response.data.data;
           
            this.countPrice(data);
       
        },
        (error) => {
           
          this.discount = 0;
          this.countPrice();
         
          var mssg = error.response.data.message  ?  error.response.data.message :error.response.data.error;
         
          Swal.fire({
                text: mssg,
                icon: "error",
                customClass: {
                confirmButton: "btn fw-bold btn-danger",
                },
            });
        }
        );
      }
       
      }else{
        Swal.fire({
                text: "Anda harus login terlebih dahulu.",
                icon: "error",
                customClass: {
                confirmButton: "btn fw-bold btn-danger",
                },
            }).then((result) =>{
            if (result.isConfirmed) {
                this.$router.push({
                  path: '/login',
                  query: {
                    booking_url: this.$route.fullPath,
                  },
                });
              }
          });
      }
    }else{
      Swal.fire({
                  text: 'Kode promo harus diisi',
                  icon: "error",
                  customClass: {
                  confirmButton: "btn fw-bold btn-danger",
                  },
              });
    }
  },
  clearPromotionCode(){
      this.form.promotion_code = '';
      this.countPrice();
  },
  setTime(e){
      
    if(this.term == 'year' || this.term == 'month' || this.term == 'no term'){
      //
    }else{
      let hour = e.target.value.split(':')[0]
      e.target.value = `${hour}:00`
      this.form.started_at =  `${hour}:00`;
      
    }

    
  
  },
}
};
</script>