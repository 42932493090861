<template>
  <div class="bg-white">
    <section class="carousel pb-10 text-center md:pt-10">
      <Carousel :transition="500" :autoplay="2000"
        class="mt-5"
      >
        <Slide  v-for="item in sliders"
        :key="item.id">
          <div class="w-full">
            <div
              class="w-full h-[510px] text-white py-24 px-10 bg-cover bg-no-repeat"
              v-bind:style="{
                'background-image': 'url(' + url + item.picture_path + ')',
              }"
            >
              <div class="pt-20 text-justify xl:pl-10 break-words">
                <p
                  class="text-6xl font-bold text-uppercase"
                  style="color: #d2b064"
                >
                  {{ item.name }}
                </p>
                <p class="text-2xl mb-10 leading-none text-black">
                  {{ item.content }}
                </p>
                <!-- <a href="#" class="py-4 px-8 text-white font-bold uppercase text-xs rounded" style="background-color:#D3693C">Contact us</a> -->
              </div>
            </div>
          </div>
        </Slide>
        <template #addons>
          <Navigation class="rounded-full bg-yellow-500"/>
        </template>
      </Carousel>
    </section>

    <section class="working_room mx-auto w-full p-20">
      <p class="text-black tracking-tight font-display text-2xl font-bold">
        Ruang Kerja
      </p>
      <div
        class="mt-10 grid xl:grid-cols-4 lg:xl:grid-cols-4 md:xl:grid-cols-3 sm:xl:grid-cols-1 gap-4"
      >
        <div v-for="item in categories" :key="item.id">
          <div
            class="xl:h-[15rem] lg:h-[15rem] md:h-[15rem] sm:md:h-[15rem] rounded-2xl overflow-hidden shadow-lg bg-neutral-100 bg-white border border-brand animate-fade-up animate-duration-1000 animate-delay-500"
          >
            <div class="px-6 py-4">
              <h3
                class="flex items-center text-xl font-semibold text-neutral-700 font-primary text-3xl"
              >
                {{ item.name }}
              </h3>
            </div>
            <div class="px-6 py-2">
              <p class="text-neutral-700 font-normal text-base font-primary">
                {{ item.desc ? item.desc.substring(0, 100) + ".." : "" }}
              </p>
            </div>
            <div class="px-6 py-3">
              <a
                href="#"
                class="inline-flex items-center font-second-brand font-primary"
                @click="pushRoute(item.id)"
              >
                Cari ruang kerja
                <!-- <svg  class="w-5 h-5 ml-2 text-amber-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
                  <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
                </svg> -->
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="facility mx-auto w-full p-20">
      <div
        class="text-center text-3xl font-semibold leading-9 text-neutral-700 font-primary"
      >
        Fasilitas
      </div>
      <div
        class="mt-10 grid gap-4 xl:grid-cols-4 lg:xl:grid-cols-4 md:xl:grid-cols-3 sm:xl:grid-cols-1"
      >
        <div class="h-32 p-6 justify-start items-center gap-3 inline-flex">
          <img src="../assets/icon/new_icon_printer.png" alt="" />
          <div
            class="text-stone-600 text-base font-semibold leading-normal font-primary"
          >
            Fasilitas Printer untuk menunjang produktivitas
          </div>
        </div>
        <div class="h-32 p-6 justify-start items-center gap-3 inline-flex">
          <img src="../assets/icon/new_icon_wifi.png" alt="" />
          <div
            class="text-stone-600 text-base font-semibold leading-normal font-primary"
          >
            Akses Internet dengan kecepatan tinggi
          </div>
        </div>
        <div class="h-32 p-6 justify-start items-center gap-3 inline-flex">
          <img src="../assets/icon/new_icon_mail.png" alt="" />
          <div
            class="text-stone-600 text-base font-semibold leading-normal font-primary"
          >
            Surat menyurat maupun penerimaan paket
          </div>
        </div>
        <div class="h-32 p-6 justify-start items-center gap-3 inline-flex">
          <img src="../assets/icon/new_icon_people.png" alt="" />
          <div
            class="text-stone-600 text-base font-semibold leading-normal font-primary"
          >
            Komunitas dan incubator yang dimiliki telkom
          </div>
        </div>
        <div class="h-32 p-6 justify-start items-center gap-3 inline-flex">
          <img src="../assets/icon/new_icon_refrigerator.png" alt="" />
          <div
            class="text-stone-600 text-base font-semibold leading-normal font-primary"
          >
            Dengan peralatan makan & minum lainya.
          </div>
        </div>
        <div class="h-32 p-6 justify-start items-center gap-3 inline-flex">
          <img src="../assets/icon/new_icon_parking.png" alt="" />
          <div
            class="text-stone-600 text-base font-semibold leading-normal font-primary"
          >
            Area parkir yang luas dan aman
          </div>
        </div>
        <div class="h-32 p-6 justify-start items-center gap-3 inline-flex">
          <img src="../assets/icon/new_icon_locker.png" alt="" />
          <div
            class="text-stone-600 text-base font-semibold leading-normal font-primary"
          >
            Sebagai pendukung kenyamanan dan keamanan
          </div>
        </div>
        <div class="h-32 p-6 justify-start items-center gap-3 inline-flex">
          <img src="../assets/icon/new_icon_pin_location.png" alt="" />
          <div
            class="text-stone-600 text-base font-semibold leading-normal font-primary"
          >
            yang akan disesuaikan dengan alamat Xircle
          </div>
        </div>
      </div>
    </section>

    <section class="whats_on mx-auto w-full p-20">
      <div class="flex-col xl:flex-row flex justify-between items-center">
        <p class="text-black tracking-tight font-display text-2xl font-bold">
          Whats On
        </p>
        <a href="/whats-on"
          class="font-second-brand tracking-tight font-display text-1xl font-bold"
        >
          Lihat Semua
        </a>
      </div>

      <div
        class="mt-10 grid gap-4 xl:grid-cols-4 lg:xl:grid-cols-4 md:xl:grid-cols-3 sm:xl:grid-cols-1" 
       
      >
        <div  v-for="item in whatsOn" 
        :key="item.id">
        <div class="bg-white shadow-md rounded bg-white">
          <div>
            <img
              src="../assets/images/article/image.png"
              class="rounded-t w-full" v-if="item.picture_path == null"
            /> 
            <img
              :src="url+item.picture_path"
              class="rounded-t w-full" v-else
            />
          </div>
          <div class="p-2">
            <article class="text-gray-700 mt-2">{{item.name}}</article>
            <div class="mt-2 text-sm font-paragraph"  v-html="item.content ? item.content.slice(0, 250 - 3) + '...' : ''">
            
            </div>
          </div>
          <div class="px-2 py-4">
            <a  class="inline-flex items-center font-second-brand cursor-pointer" @click="goToDetail(item.id)">
              Baca selengkapnya
            </a>
          </div>
        </div>
        </div>
       
      </div>
    </section>

    <section class="faq mx-auto w-full p-20">
      <div class="dark:bg-gray-800 dark:text-gray-100">
        <div class="container flex flex-col justify-center p-4 mx-auto md:p-8">
          <h2 class="mb-12 text-4xl font-bold leadi text-center sm:text-3xl">
            Frequently Asked Questions
          </h2>
          <div
            class="flex flex-col divide-y sm:px-8 lg:px-12 xl:px-32 divide-gray-700"
          >
            <details>
              <summary class="py-2 outline-none cursor-pointer focus:underline">
                Apa itu Xircle ?
              </summary>
              <div class="px-4 pb-4">
                <p>
                  Xircle sebagai salah satu produk unggulan dari Telkom Property
                  menawarkan ruang kerja bersama mulai dari coworking space,
                  service office dan meeting room.
                </p>
              </div>
            </details>
            <details>
              <summary class="py-2 outline-none cursor-pointer focus:underline">
                Apa saja fasilitas yang ditawarkan oleh Xircle ?
              </summary>
              <div class="px-4 pb-4">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Est
                  aspernatur quae, eos explicabo odit minima libero veniam
                  similique quibusdam doloribus facilis ipsa accusantium vel
                  maiores corrupti! Libero voluptate a doloribus?
                </p>
              </div>
            </details>
            <details>
              <summary class="py-2 outline-none cursor-pointer focus:underline">
                Apakah Xircle hanya bisa disewa dalam jangka waktu panjang?
              </summary>
              <div class="px-4 pb-4 space-y-2">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut
                  voluptates aspernatur dolores in consequatur doloremque
                  inventore reprehenderit, consequuntur perspiciatis architecto.
                </p>
                <p>
                  Sed consectetur quod tenetur! Voluptatibus culpa incidunt
                  veritatis velit quasi cupiditate unde eaque! Iure,
                  voluptatibus autem eaque unde possimus quae.
                </p>
              </div>
            </details>
            <details>
              <summary class="py-2 outline-none cursor-pointer focus:underline">
                Apakah saya harus memiliki akun sebelum melakukan pemesanan ?
              </summary>
              <div class="px-4 pb-4 space-y-2">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut
                  voluptates aspernatur dolores in consequatur doloremque
                  inventore reprehenderit, consequuntur perspiciatis architecto.
                </p>
                <p>
                  Sed consectetur quod tenetur! Voluptatibus culpa incidunt
                  veritatis velit quasi cupiditate unde eaque! Iure,
                  voluptatibus autem eaque unde possimus quae.
                </p>
              </div>
            </details>
            <details>
              <summary class="py-2 outline-none cursor-pointer focus:underline">
                Bagaimana cara pemesanan Xircle ?
              </summary>
              <div class="px-4 pb-4 space-y-2">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut
                  voluptates aspernatur dolores in consequatur doloremque
                  inventore reprehenderit, consequuntur perspiciatis architecto.
                </p>
                <p>
                  Sed consectetur quod tenetur! Voluptatibus culpa incidunt
                  veritatis velit quasi cupiditate unde eaque! Iure,
                  voluptatibus autem eaque unde possimus quae.
                </p>
              </div>
            </details>
          </div>
        </div>
      </div>
    </section>

    <section class="our_group mx-auto w-full p-20">
      <div
        class="text-center text-3xl font-semibold leading-9 text-neutral-700 font-primary"
      >
        Other Service By TelkomProperty
      </div>
      <div
        class="mt-10 grid gap-4 xl:grid-cols-[repeat(auto-fit,_40%)] lg:xl:grid-cols-[repeat(auto-fit,_40%)] md:xl:grid-cols-[repeat(auto-fit,_40%)] sm:xl:grid-cols-1 justify-center"
      >
        <a href="http://xsportainment.id/" target="_blank">
          <img src="../assets/images/group/team_xsport.png" alt="" />
        </a>
        <a href="https://telprocreativehub.id/" target="_blank">
          <img src="../assets/images/group/team_creative_hub.png" alt="" />
        </a>
      </div>
    </section>

    <!-- end section content area -->
    <div
      id="modal"
      v-if="isModal == true"
      :style="isModal == false ? 'display: none' : ''"
      class="fixed top-0 left-0 z-80 w-screen h-screen bg-black/70 flex justify-center items-center"
    >
      <!-- The close button -->
      <a
        class="fixed z-90 top-30 right-8 text-white text-5xl font-bold"
        href="javascript:void(0)"
        @click="isModal = false"
        >&times;</a
      >

      <!-- A big image will be displayed here -->
      <img
        id="modal-img"
        class="max-w-[800px] max-h-[600px] object-cover mt-24"
        :src="url + event.picture"
        @click="isModal = false"
      />
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import AuthService from "@/services/auth.service";
import ApiService from "@/services/api.service";
import productService from "@/services/product.service";
import { webUrl } from "../utils/config";
import { Carousel, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
export default {
  name: "Home",
  components: {  
      Carousel,
      Slide,
      Navigation,
    },
  data() {
    return {
      isModal: false,
      url: webUrl,
      tenant: "",
      image_src: "../assets/image/banner/banner.jpg",
      isTab: "",
      isTabGallery: "",
      categories: [],
      secret: "123#$%",
      event: {},
      sliders: [],
      page:1,
      totalPage:4,
      bgImg: {
        backgroundImage: `url(${require("../assets/slider/Home/corusel1.png")})`,
      },
      whatsOn:[]
    };
  },
  computed: {},
  mounted() {
    // let i = 0;
    // setInterval(() => {
    //   if (i > this.images.length - 1) {
    //     i = 0;
    //   }
    //   this.active = i;
    //   i++;
    // }, 2000);
    AuthService.getDataTenant().then(
      (response) => {
        var data = response.data;
        this.tenant = data;
        this.getCarousel(this.tenant.id);
        this.getWhatOn(this.tenant.id);
        this.getCategories(this.tenant.id);
        this.getEvent(this.tenant.id);
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
  methods: {
    getCategories(tenantId) {
      productService.getDataProductCategories(tenantId).then(
        (response) => {
          var data = response.data.data;
          this.categories = data;
          this.isTab = this.categories[0].code;
          this.isTabGallery = this.categories[0].code;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    getCarousel(tenantId) {
      productService.getWebContent(tenantId, "carousel_home").then(
        (response) => {
          var data = response.data.data;
          this.sliders = data;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    getWhatOn(tenantId) {
      productService.getWebContentWithPaginate(tenantId, "whats_on",this.page,this.totalPage).then(
        (response) => {
          var data = response.data.data;
          this.whatsOn = data.data;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    handleTab(val) {
      this.isTab = val;
    },
    handleTabGallery(val) {
      this.isTabGallery = val;
    },
    pushRoute(categoryId = null) {
      if (categoryId != null) {
        this.$router
          .push({
            name: "service",
            query: {
              category_id: categoryId,
            },
          })
          .then((response) => {
            location.reload();
          });
      }
    },
    pushRouteLocation(locationId) {
      if (locationId != null) {
        this.$router
          .push({
            name: "product",
            query: {
              location_id: locationId,
            },
          })
          .then((response) => {
            location.reload();
          });
      }
    },
    pushRouteService(id) {
      this.$router
        .push({
          name: "service",
          query: {
            category_id: id,
          },
        })
        .then((response) => {
          location.reload();
        });
    },
    getEvent(tenantId) {
      productService.getEvent(tenantId).then(
        (response) => {
          var data = response.data.data;
          for (let i = 0; i < data.length; i++) {
            this.event = data[i];
            this.isModal = true;
          }
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    goToDetail(id){
            this.$router
            .push({
                name: "whats-on-detail",
                query: {
                    Id: id,
                }
            })
            .then((response) => {
                location.reload();
            });
        }
  },
};
</script>
