<template>
  <div class="app">
    <div class="h-screen">
      <nav class="navbar bg-white flex items-center justify-between px-5 py-1 z-20 fixed w-full z-20" aria-label="Global">
        <div class="flex lg:flex-1">
          <a href="/" class="-m-1.5 ">
            <img class="h-[57px] w-auto" src="../assets/Logo.png" alt="" />
          </a>
        </div>
        <div class="flex lg:hidden">
          <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-black" @click="mobileMenuOpen = true">
            <span class="sr-only">Open main menu</span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6" style="color:##F3A323">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
          </button>
        </div>
        <div class="hidden lg:flex lg:gap-x-12">
          <a class='text-md font-semibold leading-6 text-amber-500 text-black'>
            <vdropdown placement="right">
              <!-- Button content -->
              <template v-slot:button>
                <span>Service</span>
              </template>
          
              <!-- Opened dropdown content -->
              <template v-slot:content>
                <div v-for="item in categories" :key="item.id">
                  <a class="flex w-full justify-between items-center rounded px-2 py-1 my-1 hover:bg-red-600 hover:text-white" href="#" @click="pushRoute(item.id)">{{item.name}}</a>
                </div>
              </template>
            </vdropdown>
          </a>
          <a href="/location" class="text-md font-semibold leading-6 text-amber-500 text-black">Location</a>
          <a  href="https://telpro.my.id/affiliate/" target="_blank" class="text-md font-semibold leading-6 text-amber-500 text-black">Affiliate</a>
          <a  href="/whats-on"  class="text-md font-semibold leading-6 text-amber-500 text-black">Whats On</a>
          <a href="/about-us" class="text-md font-semibold leading-6 text-amber-500 text-black">About Us</a>
        </div>
        <div class="hidden lg:flex lg:flex-1 lg:justify-end">
          <div v-if="currentUser">
            <vdropdown placement="right">
              <!-- Button content -->
              <template v-slot:button>
                <div v-if="currentUser.user.avatar != null">
                  <img class="ml-2 w-10 h-10 rounded-full border-2 border-gray-600 mr-3" :src="url+currentUser.user.avatar" alt="Saleh Mir" />
                </div>
                <div v-else>
                 <img class="ml-2 w-10 h-10 rounded-full border-2 border-gray-600 mr-3" src="https://raw.githubusercontent.com/vuetailwind/storage/master/avatars/saleh-avatar.jpg" alt="Saleh Mir" />
                </div>

                <span class="mr-2 text-amber-500 text-black">{{currentUser.user.name}}</span>
          
                <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M4.516 7.548c.436-.446 1.043-.481 1.576 0L10 11.295l3.908-3.747c.533-.481 1.141-.446 1.574 0 .436.445.408 1.197 0 1.615-.406.418-4.695 4.502-4.695 4.502a1.095 1.095 0 0 1-1.576 0S4.924 9.581 4.516 9.163c-.409-.418-.436-1.17 0-1.615z" />
                </svg>
              </template>
          
              <!-- Opened dropdown content -->
              <template v-slot:content>
                <router-link :to="{ path: '/profile' }">
                  <a class="flex w-full justify-between items-center rounded px-2 py-1 my-1 hover:bg-indigo-600 text-amber-500 text-black" href="#">Settings</a>
                </router-link>
          
                <hr />
          
                <a class="flex w-full justify-between items-center rounded px-2 py-1 my-1 hover:bg-red-600 text-amber-500 text-black" href="#" @click="logOut()">Logout</a>
              </template>
            </vdropdown>
          </div>
          <div v-else class='p-4'>
            <router-link :to="{ path: '/login' }">
              <a href="#" class="text-sm font-semibold leading-6 text-amber-500 text-black">Log in <span aria-hidden="true" style="color:white">&rarr;</span></a>
            </router-link>
          </div>
          
        </div>
      </nav>
      <Dialog as="div" class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
        <div class="fixed inset-0 z-50" />
        <DialogPanel class="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div class="flex items-center justify-between">
            <a href="#" class="-m-1.5 p-1.5">
              <span class="sr-only">Your Company</span>
              <img class="h-8 w-auto" src="../assets/Logo.png" alt="" />
            </a>
            <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = false">
              <span class="sr-only">Close menu</span>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div class="mt-6 flow-root">
            <div class="-my-6 divide-y divide-gray-500/10">
              <div class="space-y-2 py-6">
                <a href="/location" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-amber-500 hover:bg-gray-50 font-primary">Location</a>
                <a v-for="item in categories" :key="item.id" @click="pushRoute(item.id)" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-amber-500 hover:bg-gray-50 font-primary">{{ item.name }}</a>
              </div>
              <div class="py-6">
                <span v-if="currentUser" class="my-4">
                  <a href="/profile" class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 font-primary text-amber-500">Settings</a>
                
                </span>
                <span v-if="currentUser" class="my-4">
                  <a  class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 font-primary text-amber-500" @click="logOut()">Logout</a>
                </span>
                <span v-else>
                  <a href="/login" class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 font-primary text-amber-500">Log in</a>
                </span>
               
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
      <router-view />
      <footer class="bg-brand w-full">
        <div class="mx-auto  p-4 py-6 lg:py-8">
            <div class="md:flex md:justify-between">
            <div class="mb-6 md:mb-0 mr-4">
                <a href="#" class="flex items-center">
                    <img src="../assets/images/logo.png" class="h-10 mr-3" alt="Xircle Logo" />
                </a>
            </div>
            <div class="grid gap-8 grid-cols-2 lg:grid-cols-4">
                <div>
                    <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase text-white">Location</h2>
                    <ul class="text-gray-600  font-medium" v-for="item in locations" :key="item.id">
                        <li class="mb-2" @click="pushRoute(item.id,null)">
                            <p  class="hover:underline">{{item.name}}</p>
                        </li>
                       
                    </ul>
                </div>
                <div>
                    <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase text-white">Service</h2>
                    <ul class="text-gray-600  font-medium" v-for="item in categories" :key="item.id">
                        <li class="mb-2" @click="pushRoute(null,item.id)">
                            <p  class="hover:underline">{{item.name}}</p>
                        </li>
                    </ul>
                </div>
                <div>
                    <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase text-white">Social Media</h2>
                    <ul class="text-gray-600  font-medium">
                        <li class="mb-2">
                            <a :href="'https://www.instagram.com/xircle.id/'" target="_blank" class="hover:underline">Instagram</a>
                        </li>
                        <li class="mb-2">
                            <a :href="'https://goo.gl/maps/Wk95uTop4mgjKkQU6'" target="_blank" class="hover:underline">Maps</a>
                        </li>
                    </ul>
                </div>
                <div>
                    <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase text-white">Kontak</h2>
                    <ul class="text-gray-600  font-medium">
                        <li class="mb-4">
                            <p class="hover:underline text-ellipsis" style="width:200px">
                              {{tenant.head_office_address}}
                            </p>
                            <p class="hover:underline">
                              {{tenant.email}}
                            </p>
                        </li>
                        <li>
                            <p>{{tenant.phone}}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <hr class="my-6 border-gray-200 sm:mx-auto border-gray-700 lg:my-8" />
        <div class="sm:flex sm:items-center sm:justify-between">
            <span class="text-sm text-gray-500 sm:text-center text-gray-400">© {{ new Date().getFullYear() }} <a href="#" class="hover:underline">Xircle™</a>. All Rights Reserved.
            </span>
            <div class="flex mt-4 space-x-6 sm:justify-center sm:mt-0">
                <a :href="'https://www.facebook.com/mytelkompropertyofficial'" target="_blank" class="text-gray-500 hover:text-gray-900 text-white">
                    <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" /></svg>
                    <span class="sr-only">Facebook page</span>
                </a>
                <a :href="'https://www.instagram.com/xircle.id/'" target="_blank" class="text-gray-500 hover:text-gray-900 text-white">
                    <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd" /></svg>
                    <span class="sr-only">Instagram page</span>
                </a>
                <a :href="'https://twitter.com/_telkomproperty'" target="_blank" class="text-gray-500 hover:text-gray-900 text-white">
                    <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" /></svg>
                    <span class="sr-only">Twitter page</span>
                </a>
            </div>
        </div>
        </div>
    </footer>
    </div>
  </div>
</template>
<script>
import vdropdown from '../components/DropdownHeader.vue';
import { webUrl } from '../utils/config'
import AuthService from "@/services/auth.service";
import ApiService from '@/services/api.service';
import productService from "@/services/product.service";
  export default({
    name: "main-layout",
    components:{vdropdown},
    data() {
    return {
      tenant: "",
      mobileMenuOpen: false,
      url: webUrl,
      isLocation: false,
      locations: [],
      categories: [],
      secret: "123#$%",
      isDropDownLocation:false,
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {

    AuthService.getDataTenant().then(
        (response) => {
          var data = response.data;
          this.tenant = data;
          this.getLocation(data.id);
          this.getCategories(data.id);
          
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );



    },
    methods: {
      getLocation(tenantId){
        ApiService.getDataLocation(tenantId).then(
        (response) => {
            var data = response.data;
            this.locations = data;
            // if(data != null){
            //   localStorage.setItem('locations', JSON.stringify(data));
            // }else{
            //   localStorage.setItem('locations', null);
            // }
            // this.content = response.data;
        },
        (error) => {
            this.content =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
        }
        )
    },
    getCategories(tenantId){
      productService.getDataProductCategories(tenantId).then(
        (response) => {
          var data = response.data.data;
          this.categories = data;
          
         
         
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    hadleNav(){
      this.mobileMenuOpen = !this.isToggle;
    },
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    pushRoute(categoryId = null){
      this.$router.push({
          name: "service",
          query: {
            category_id: categoryId
          },
        }).then((response) => {
          location.reload();
        });
      
    }
    },
  });
</script>