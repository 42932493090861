<template>
  <div class="bg-gray-50">
    <div class="bg-white">
      <section class="pb-2 text-center pt-20">
        <div
          class="text-white py-44 px-10 bg-cover bg-no-repeat bg-center w-full" v-bind:style="{
            'background-image': 'linear-gradient(to left, rgba(250, 250, 250, 0), rgba(255, 255, 255, 0.90)), url(' + url + category.picture_path + ')',
          }"
        
        >
          <div class="xl:pl-10 lg:w-1/2 text-center">
            <p
              class="font-medium text-gray-800 text-6xl md:text-6xl xl:text-7xl mb-10"
              style="color: #d2b064"
            >
              {{ category.name }}
            </p>
          
          </div>
        </div>
      </section>
      <section class="mx-auto w-full mt-5 px-24 pb-10">
        <h1
          class="text-black tracking-tight font-display text-3xl font-bold  font-brand"
        >
          {{ category.name }}
        </h1>
        <div class="mt-2 text-justify">
          <p
            class="text-xl leading-none font-paragraph break-words"
          >
            {{ category.desc }}
          </p>
        </div>
      </section>
    </div>

    <section class="mt-5 p-20 bg-white">

      <div class="section_carousel carousel pb-10 text-center " v-if="isProductPrices == false">
        <Carousel :breakpoints="breakpoints" :transition="500" :autoplay="2000" class="hidden md:block sm:block">
       
            <Slide v-for="item in products" :key="item.id">
              <div class="w-full flex-col xl:flex-row flex justify-around items-center p-20">
                <div>
                  <div
                    class="text-black text-3xl font-semibold text-left"
                  >
                    {{ item.name }}
                  </div>
                  <div class="font-xl text-black font-normal text-left">
                    {{ item.desc  }}
                  </div>
              </div>
              <img
                  class="w-[250px] h-[150px]"
                  src="../assets/images/hero-content.png"
                  alt="Sunset in the mountains"
                />
              </div>
            </Slide>
            <template #addons>
              <Navigation class="rounded-full bg-yellow-500"/>
            </template>
          
         
        </Carousel>
      </div>
      <div class="section_carousel carousel pb-10 text-center" v-else >
        <Carousel :transition="500" :autoplay="2000" class="hidden md:block sm:block">
          <Slide v-for="item in productPrices" :key="item.id">
            <div class="flex-col xl:flex-row flex justify-around">
              <div>
                  <div
                    class="text-black text-3xl font-semibold"
                  >
                    {{ item.name }}
                  </div>
                  <div class="font-xl text-black font-normal">
                    {{ item.desc }}
                  </div>
              </div>
              <div v-if="item.picture_path != null">
                <img
                  class="w-[336px] h-[250px]"
                  :src="webUrl + item.picture_path"
                  alt="Sunset in the mountains"
                />
              </div>
              <div v-else>
                <img
                  class="w-[336px] h-[250px]"
                  src="../assets/images/hero-content.png"
                  alt="Sunset in the mountains"
                />
              </div>
            </div>
          </Slide>
        </Carousel>
      </div>

      <div id="section_product mt-10">
        <div class="mx-auto 2xl:max-w-7xl max-w-7xl ">
          <div class="flex-col xl:flex-row flex justify-between">
            <div
            class="text-center text-[30px] font-semibold leading-9 text-neutral-700 font-primary"
          >
            Pilihan {{ category.name }}
            </div>
            <select id="countries" class="
            bg-white 
            border 
            border-gray-300 
            text-black 
            text-sm 
            rounded-lg 
            focus:ring-white-500 
            focus:border-white-500 
            block lg:w-[200px] md:w-full p-3"
            v-model="location_id"
            @change="findProduct()"
            >
            <option value="" disabled>Select Location</option>
                <option
                v-for="item in locations" 
                v-bind:value="item.id"
                v-bind:key="item.id"
            >
                {{ item.name }}
            </option>
              
            </select>
          </div>
         
        </div>
        <div class="mx-auto 2xl:max-w-7xl max-w-7xl justify-center">
          <div class="md:mt-20 lg:mt-0 xl:md-0">
            <div v-if="isProductPrices == false">
              <div
                class="mt-10 grid gap-4 md:grid-cols-2 lg:gap-4 xl:grid-cols-4 justify-center"
              >
                <div v-for="item in products" :key="item.id">

                <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow">
                  <a href="#">
                      <img class="rounded-t-lg" src="../assets/images/hero-content.png" alt="" />
                  </a>
                  <div class="p-3">
                      <a href="#">
                          <h5 class="mb-2 text-base font-bold tracking-tight text-black"> {{ item.name }}</h5>
                      </a>
                      <!-- <p class="font-xs text-black mt-2">{{ location.name }}</p> -->
                      <p class="mb-3 text-base font-bold text-black">  Rp.{{ formatPrice(item.price) }}</p>
                    
                      <div
                        class="self-stretch text-muted text-sm mb-3"
                      >
                        PPN 11% | syrat & ketentuan berlaku
                      </div>
                      <button
                      class=" self-stretch  flex"
                      @click="
                        pushRoute(
                          item.id, item.has_room
                        )
                      "
                    >
                      <div
                        class="text-center font-second-brand text-md font-semibold leading-normal"
                        style="font-size: 16px !important"
                      >
                        Book Now
                      </div>
                    </button>
                  </div>
                </div>

                  
                </div>
              </div>
              <div class="max-w-lg mx-auto my-12" v-if="products.length > 0">
                <pagination
                  :total-pages="totalPages"
                  :total="total"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :has-more-pages="hasMorePages"
                  @pagechanged="showMore"
                >
                </pagination>
              </div>
            </div>
            <div v-else>
              <div class="mt- grid gap-8 md:grid-cols-2 lg:gap-8 xl:grid-cols-4">
                <div v-for="(detail, index) in paginated" :key="index">
                  <div
                    class="w-[295px] h-100 px-6 py-8 bg-neutral-100 bg-white border border-amber-500 rounded-tr-lg rounded-bl-lg flex-col justify-center items-start gap-6 inline-flex hover:shadow-[0_4px_0px_rgb(0,0,0)] text-black bg-white ease-out hover:translate-y-1 transition-all font-primary"
                  >
                    <div v-if="item.picture_path != null">
                      <img
                        class="w-[244px] h-[163px]"
                        :src="webUrl + item.picture_path"
                        alt="Sunset in the mountains"
                      />
                    </div>
                    <div v-else>
                      <img
                        class="w-[244px] h-[163px]"
                        src="../assets/images/hero-content.png"
                        alt="Sunset in the mountains"
                      />
                    </div>
                    <div
                      class="self-stretch h-30 flex-col justify-center items-start gap-2 flex"
                    >
                      <div
                        class="self-stretch text-neutral-700 text-2xl font-semibold"
                      >
                        {{ detail.name }}
                      </div>
  
                      <div
                        class="self-stretch text-neutral-700 text-base font-semibold leading-normal"
                        v-if="detail.term == 'no term'"
                      >
                        Rp.{{ formatPrice(detail.price) }}
                      </div>
                      <div
                        class="self-stretch text-neutral-700 text-base font-semibold leading-normal"
                        v-else
                      >
                        Rp.{{ formatPrice(detail.price) }} / {{ detail.term }}
                      </div>
  
                    
                    </div>
  
                    <div
                      class="self-stretch h-10 flex-col justify-center items-end gap-2.5 flex mt-5"
                    >
                      <div
                        class="h-10 bg-amber-500 rounded justify-end items-center gap-2 inline-flex"
                      >
                        <button
                          class="w-[200px] self-stretch px-4 py-2.5 justify-center items-center gap-2 flex"
                          @click="bookRoom(detail.id)"
                        >
                          <div
                            class="text-center text-neutral-700 text-base font-semibold leading-normal"
                            style="font-size: 14px !important"
                          >
                            Book Now
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                 
                </div>
              </div>
              <div class="max-w-lg mx-auto my-12" v-if="paginated.length > 0">
                <pagination
                  :total-pages="totalPages"
                  :total="total"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :has-more-pages="hasMorePages"
                  @pagechanged="showMoreRoom"
                >
                </pagination>
              </div>
            </div>
          </div>
        </div>
       

        <!-- Faq -->
      </div>
    </section>

    <section class="facility mx-auto w-full p-20 bg-white mt-5">
      <div
        class="text-center text-3xl font-semibold leading-9 text-neutral-700 font-primary"
      >
        Fasilitas
      </div>
      <div
        class="mt-10 grid gap-4 xl:grid-cols-4 lg:xl:grid-cols-4 md:xl:grid-cols-3 sm:xl:grid-cols-1"
      >
        <div class="h-32 p-6 justify-start items-center gap-3 inline-flex">
          <img src="../assets/icon/new_icon_printer.png" alt="" />
          <div
            class="text-stone-600 text-base font-semibold leading-normal font-primary"
          >
            Fasilitas Printer untuk menunjang produktivitas
          </div>
        </div>
        <div class="h-32 p-6 justify-start items-center gap-3 inline-flex">
          <img src="../assets/icon/new_icon_wifi.png" alt="" />
          <div
            class="text-stone-600 text-base font-semibold leading-normal font-primary"
          >
            Akses Internet dengan kecepatan tinggi
          </div>
        </div>
        <div class="h-32 p-6 justify-start items-center gap-3 inline-flex">
          <img src="../assets/icon/new_icon_mail.png" alt="" />
          <div
            class="text-stone-600 text-base font-semibold leading-normal font-primary"
          >
            Surat menyurat maupun penerimaan paket
          </div>
        </div>
        <div class="h-32 p-6 justify-start items-center gap-3 inline-flex">
          <img src="../assets/icon/new_icon_people.png" alt="" />
          <div
            class="text-stone-600 text-base font-semibold leading-normal font-primary"
          >
            Komunitas dan incubator yang dimiliki telkom
          </div>
        </div>
        <div class="h-32 p-6 justify-start items-center gap-3 inline-flex">
          <img src="../assets/icon/new_icon_refrigerator.png" alt="" />
          <div
            class="text-stone-600 text-base font-semibold leading-normal font-primary"
          >
            Dengan peralatan makan & minum lainya.
          </div>
        </div>
        <div class="h-32 p-6 justify-start items-center gap-3 inline-flex">
          <img src="../assets/icon/new_icon_parking.png" alt="" />
          <div
            class="text-stone-600 text-base font-semibold leading-normal font-primary"
          >
            Area parkir yang luas dan aman
          </div>
        </div>
        <div class="h-32 p-6 justify-start items-center gap-3 inline-flex">
          <img src="../assets/icon/new_icon_locker.png" alt="" />
          <div
            class="text-stone-600 text-base font-semibold leading-normal font-primary"
          >
            Sebagai pendukung kenyamanan dan keamanan
          </div>
        </div>
        <div class="h-32 p-6 justify-start items-center gap-3 inline-flex">
          <img src="../assets/icon/new_icon_pin_location.png" alt="" />
          <div
            class="text-stone-600 text-base font-semibold leading-normal font-primary"
          >
            yang akan disesuaikan dengan alamat Xircle
          </div>
        </div>
      </div>
    </section>

    <section class="faq mx-auto w-full p-20 bg-white mt-5">
      <div class="dark:bg-gray-800 dark:text-gray-100">
        <div class="container flex flex-col justify-center p-4 mx-auto md:p-8">
          <h2 class="mb-12 text-4xl font-bold leadi text-center sm:text-3xl">
            Frequently Asked Questions
          </h2>
          <div
            class="flex flex-col divide-y sm:px-8 lg:px-12 xl:px-32 divide-gray-700"
          >
            <details>
              <summary class="py-2 outline-none cursor-pointer focus:underline">
                Apa itu Xircle ?
              </summary>
              <div class="px-4 pb-4">
                <p>
                  Xircle sebagai salah satu produk unggulan dari Telkom Property
                  menawarkan ruang kerja bersama mulai dari coworking space,
                  service office dan meeting room.
                </p>
              </div>
            </details>
            <details>
              <summary class="py-2 outline-none cursor-pointer focus:underline">
                Apa saja fasilitas yang ditawarkan oleh Xircle ?
              </summary>
              <div class="px-4 pb-4">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Est
                  aspernatur quae, eos explicabo odit minima libero veniam
                  similique quibusdam doloribus facilis ipsa accusantium vel
                  maiores corrupti! Libero voluptate a doloribus?
                </p>
              </div>
            </details>
            <details>
              <summary class="py-2 outline-none cursor-pointer focus:underline">
                Apakah Xircle hanya bisa disewa dalam jangka waktu panjang?
              </summary>
              <div class="px-4 pb-4 space-y-2">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut
                  voluptates aspernatur dolores in consequatur doloremque
                  inventore reprehenderit, consequuntur perspiciatis architecto.
                </p>
                <p>
                  Sed consectetur quod tenetur! Voluptatibus culpa incidunt
                  veritatis velit quasi cupiditate unde eaque! Iure,
                  voluptatibus autem eaque unde possimus quae.
                </p>
              </div>
            </details>
            <details>
              <summary class="py-2 outline-none cursor-pointer focus:underline">
                Apakah saya harus memiliki akun sebelum melakukan pemesanan ?
              </summary>
              <div class="px-4 pb-4 space-y-2">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut
                  voluptates aspernatur dolores in consequatur doloremque
                  inventore reprehenderit, consequuntur perspiciatis architecto.
                </p>
                <p>
                  Sed consectetur quod tenetur! Voluptatibus culpa incidunt
                  veritatis velit quasi cupiditate unde eaque! Iure,
                  voluptatibus autem eaque unde possimus quae.
                </p>
              </div>
            </details>
            <details>
              <summary class="py-2 outline-none cursor-pointer focus:underline">
                Bagaimana cara pemesanan Xircle ?
              </summary>
              <div class="px-4 pb-4 space-y-2">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut
                  voluptates aspernatur dolores in consequatur doloremque
                  inventore reprehenderit, consequuntur perspiciatis architecto.
                </p>
                <p>
                  Sed consectetur quod tenetur! Voluptatibus culpa incidunt
                  veritatis velit quasi cupiditate unde eaque! Iure,
                  voluptatibus autem eaque unde possimus quae.
                </p>
              </div>
            </details>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import "@coreui/coreui/dist/css/coreui.min.css";
import AuthService from "@/services/auth.service";
import ApiService from "@/services/api.service";
import productService from "@/services/product.service";
import Pagination from "@/components/Pagination";
import { webUrl } from "../utils/config";
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
export default {
  name: "service-page",
  components: { 
      Carousel,
      Slide,
      Navigation,
     Pagination 
    },
  data() {
    return {
      url: webUrl,
      Id: "",
      category: {},
      isLoading: true,
      isProductPrices: false,
      tenant_id: "",
      page: 1,
      totalPages: 10,
      total: 10,
      perPage: 8,
      currentPage: 1,
      hasMorePages: true,
      location_id: "",
      locations : [],
      products: [],
      product: {},
      productPrices: [],
    };
  },
  computed: {
    indexStart() {
      return (this.currentPage - 1) * this.perPage;
    },
    indexEnd() {
      return this.indexStart + this.perPage;
    },
    paginated() {
      return this.productPrices.slice(this.indexStart, this.indexEnd);
    },
    dataTenant() {
      return JSON.parse(localStorage.getItem("tenant"));
    },
  },
  mounted() {
    let data = this.$route.query;

    if (data.category_id) {
      this.Id = data.category_id;
    }

    AuthService.getDataTenant().then(
      (response) => {
        var data = response.data;
        this.tenant_id = data.id;
        this.getLocation(this.tenant_id);
        this.getCategoryById(this.Id, this.tenant_id);
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getLocation(tenantId) {
      ApiService.getDataLocation(tenantId).then(
        (response) => {
          var data = response.data;
          this.locations = data;
          if (this.location_id == "") {
            this.location_id = this.locations[0].id;
          }
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    getCategoryById(Id, tenantId) {
      productService.getCategoriesById(Id, tenantId).then(
        (response) => {
          var data = response.data.data;
          this.category = data;
          this.getProduct();
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    getProduct() {
      productService
        .getDataProducts(
          this.tenant_id,
          this.Id,
          this.location_id,
          this.page
        )
        .then(
          (response) => {
            var data = response.data.data;
            this.products = data.data;

            this.currentPage = data.current_page;
            this.total = data.total;
            this.totalPages = data.last_page;
            this.last_page = data.last_page;
            this.isLoading = false;
            this.isProductPrices = false;
          },
          (error) => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
    },
    findProduct() {
      productService
        .getDataProducts(
          this.tenant_id,
          this.Id,
          this.location_id,
          this.page
        )
        .then(
          (response) => {
            var data = response.data.data;
            this.isLoading = true;
            this.products = data.data;

            this.currentPage = data.current_page;
            this.total = data.total;
            this.totalPages = data.last_page;
            this.last_page = data.last_page;
            this.isLoading = false;
            this.isProductPrices = false;
          },
          (error) => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
    },
    showMore(page) {
      this.page = page;
      this.currentPage = page;

      productService
        .getDataProducts(
          this.tenant_id,
          this.Id,
          this.location_id,
          this.page
        )
        .then(
          (response) => {
            var data = response.data.data;
            this.products = data.data;
            this.currentPage = data.current_page;
            this.total = data.total;
            this.totalPages = data.last_page;
            this.last_page = data.last_page;
          },
          (error) => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
    },
    showMoreRoom(page) {
      this.page = page;
      this.currentPage = page;
    },
    pushRoute(Id) {
      if (Id != null) {
        this.$router
          .push({
            name: "detail-product",
            query: {
              location_id: this.location_id,
              product_id: Id
            }
          })
          .then((response) => {
            location.reload();
          });
      }
    },
  },
};
</script>
