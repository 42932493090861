<template>
    <div class="pt-20">
       <div v-for="(item, index) in locations" :key="index">
        <div v-if="index % 2 == 0">
          <section class="mx-auto 2xl:max-w-7xl max-w-7xl justify-center p-20 items-center">
            <div class="mt-10 grid lg:grid-cols-2 md:grid-cols-2 sm:lg:grid-cols-1 gap-8 justify-center items-center ">
                <div>
                    <p class="text-6xl font-bold text-uppercase" style="color: #D2B064">{{item.name}}</p>
                    <p class="text-1xl mb-10 leading-none  text-black">{{ item.desc }}</p>
                </div>
                <img src="../assets/images/location/media.png" alt=""  v-if="item.picture_path == null">
                <img :src="url+item.picture_path" alt=""  v-else>
            
            </div>
        </section>
        </div>
        <div v-else>
          <section class="mx-auto 2xl:max-w-7xl max-w-7xl justify-center p-20 items-center">
            <div class="mt-10 grid lg:grid-cols-2 md:grid-cols-2 sm:lg:grid-cols-1 gap-8 justify-center items-center ">
              <img src="../assets/images/location/media.png" alt=""  v-if="item.picture_path == null">
                <img :src="url+item.picture_path" alt=""  v-else>
              <div>
                  <p class="text-6xl font-bold text-uppercase" style="color: #D2B064">{{item.name}}</p>
                  <p class="text-1xl mb-10 leading-none  text-black">{{ item.desc }}</p>
              </div>
               
            
            </div>
          </section>
        </div>
       
       </div>
    </div>
</template>
<script>
import AuthService from "@/services/auth.service";
import ApiService from "@/services/api.service";
import { webUrl } from "../utils/config";
export default {
  name: "location-page",
  data() {
    return {
      url: webUrl,
      locations: [],
    };
  },
  mounted() {
    AuthService.getDataTenant().then(
      (response) => {
        var data = response.data;
        this.tenant_id = data.id;
        this.getLocation(this.tenant_id);
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
  methods: {
    getLocation(tenantId) {
      ApiService.getDataLocation(tenantId).then(
        (response) => {
          var data = response.data;
          this.locations = data;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  }
}
</script>