<template>
    <div class="pt-20">
        <div class="mx-auto 2xl:max-w-7xl  md:px-36 px-20 pt-10 mb-10">
            <div class="max-w-sm w-full lg:max-w-full lg:flex">
                <div class="grid gap-10 md:grid-cols-2 lg:gap-10 xl:grid-cols-2">
                  
    
                  <div class=" bg-white rounded-lg pt-10 flex flex-col justify-center leading-normal">
                    <div class="mb-8">
                      <div class="text-black font-black  text-2xl mb-2">About Us</div>
                      <p class="text-black font-normal leading-10">
                        <span class="text-black font-bold">Xircle</span> adalah salah satu layanan ruang kerja yang ditawarkan oleh Telkom Property. Dirancang untuk memenuhi kebutuhan pasar akan kantor pribadi, ruang kerja bersama, ruang pertemuan, dan ruang acara. Merek Xircle sendiri terinspirasi dari sebuah lingkaran, yang merepresentasikan gerakan dinamis dan ketidakterbatasan. Dengan makna tersebut diharapkan Xircle dapat memberikan layanan yang tidak terbatas dan dapat memberikan kualitas layanan tertinggi melalui inovasi dan perubahan yang dinamis.
                      </p>
                    </div>
                  </div>
    
                  <img alt="" class="object-cover w-full rounded-xl" src="../assets/images/hero-banner.png" style="height:470px"/>
                </div>  
                
              </div>
        </div>
        <div class="mx-auto 2xl:max-w-xl  md:px-36 px-20 mb-10">
            <div class="max-w-sm w-full lg:max-w-full ">
                
                <p class="text-black tracking-tight font-display text-2xl font-bold">Our Value</p>
                <p class="mt-2 text-black font-normal leading-10">Dirancang untuk mendukung kolaborasi komunitas-komunitas dan para entrepreneurs baik
                    secara individu maupun kelompok dalam berbagi ide dan gagasan.
                    Merek Xircle sendiri terinspirasi dari sebuah lingkaran, yang merepresentasikan gerakan
                    dinamis dan ketidakterbatasan. Dengan makna tersebut diharapkan Xircle dapat
                    memberikan layanan yang tidak terbatas dan dapat memberikan kualitas layanan tertinggi melalui inovasi dan perubahan yang dinamis.</p>
            </div>
        </div>

        <div class="mx-auto 2xl:max-w-xl  md:px-36 px-20 mb-10">
            <div class="mx-auto text-left">
              <p class="text-black tracking-tight font-display text-2xl font-bold">Our Service</p>
            </div>
            <div class="grid pt-6 text-left border-gray-200 md:gap-16 dark:border-gray-700 md:grid-cols-2">
              <div>
                  <div class="mb-10">
                      <h3 class="flex items-center mb-4 text-lg font-medium text-black dark:text-black">
                        Coworking
                      </h3>
                      <p class="text-gray-500 dark:text-gray-400">Ruang kerja terbuka yang
                        didesain bagi pekerja paruh
                        waktu ataupun jenis pekerjaan
                        lain yang mengutamakan
                        fleksibilitas. Layanan ini dapat
                        disewa secara harian</p>
                  </div>

                <div class="mb-10">
                    <h3 class="flex items-center mb-4 text-lg font-medium text-black dark:text-black">
                      Event Space
                    </h3>
                    <p class="text-gray-500 dark:text-gray-400">
                        Ruang tertutup yang didesain
                        untuk kegiatan workshop,
                        webinar, maupun kegiatan
                        lainnya dengan kapasitas
                        ruangan mencapai 50 orang
                    </p>
                 </div>


                 <div class="mb-10">
                    <h3 class="flex items-center mb-4 text-lg font-medium text-black dark:text-black">
                      Virtual office
                    </h3>
                    <p class="text-gray-500 dark:text-gray-400">
                        Fasilitas penyewaan ruang kantor secara virtual, dimana para penyewa hanya berhak memiliki alamat dari kantor tersebut, tapi tidak memiliki ruangan secara fisik
                    </p>
                 </div>
                 
              </div>
              <div>
                <div class="mb-10">                        
                  <h3 class="flex items-center mb-4 text-lg font-medium text-black dark:text-black">
                      Serviced Office
                  </h3>
                    <p class="text-gray-500 dark:text-gray-400">
                        Ruang kerja yang didesain untuk mendukung aktifitas kerja dan sudah siap pakai dengan kapasitas 5-6  orang per orangan    
                    </p>
                </div>

                <div class="mb-10">
                    <h3 class="flex items-center mb-4 text-lg font-medium text-black dark:text-black">
                     Meeting room
                    </h3>
                    <p class="text-gray-500 dark:text-gray-400">
                        Ruang meeting yang dapat disewa harian dengan kapasitas 4-6 orang </p>

                 </div>
                  
              </div>
            </div>
          </div>
    </div>
</template>