import {
  Agent,
  BankAccounts,
  Complimentary,
  Customers,
  Employee,
  Invoices,
  Location,
  Locations,
  Product,
  ProductCategories,
  Referral,
  SalesOrders,
  ServiceUsage,
  Trademark,
  Promotion,
  Events,
  WebContent,
  WebContentGetByCode,
} from "@/utils/config";
import axios from "axios";
import authHeader from "./auth-header";
class ProductService {
  getDataLocations(tenant_id) {
    return axios.get(Location + "?tenant_id=" + tenant_id, {
      headers: authHeader(),
    });
  }

  getDataLocationById(tenant_id, Id) {
    return axios.get(Locations + "/" + Id + "?tenant_id=" + tenant_id, {
      headers: authHeader(),
    });
  }

  getDataProducts(tenantId, categoryId = "", locationId = "", page) {
    return axios.get(
      Product +
        "?tenant_id=" +
        tenantId +
        "&product_category_id=" +
        categoryId +
        "&location_id=" +
        locationId +
        "&page=" +
        page +
        "&total_per_page=8",
      { headers: authHeader() }
    );
  }

  getDataProductsById(tenant_id, Id) {
    return axios.get(Product + "/" + Id + "?tenant_id=" + tenant_id, {
      headers: authHeader(),
    });
  }

  getDataProductCategories(tenant_id) {
    return axios.get(ProductCategories + "?tenant_id=" + tenant_id, {
      headers: authHeader(),
    });
  }

  getCategoriesById(Id, tenant_id) {
    return axios.get(ProductCategories + "/" + Id + "?tenant_id=" + tenant_id, {
      headers: authHeader(),
    });
  }

  getDataOrders(
    status = "",
    location = "",
    product = "",
    customer_id = "",
    date = "",
    contact_id,
    tenant_id
  ) {
    return axios.get(
      SalesOrders +
        "?tenant_id=" +
        tenant_id +
        "&customer_id=" +
        customer_id +
        "&status=" +
        status +
        "&location_id=" +
        location +
        "&primary_product_id=" +
        product +
        "&contact_id=" +
        contact_id +
        "&started_at=" +
        date,
      { headers: authHeader() }
    );
  }

  getDataOrderDetail(id) {
    return axios.get(SalesOrders + "/" + id, { headers: authHeader() });
  }

  addToCart(cart) {
    console.log(cart);
    localStorage.setItem("cart", JSON.stringify(cart));
  }

  removeFromCart(cart) {
    localStorage.removeItem("cart");
    window;
    // console.log(state)
    // let index = state.mycart.indexOf(cart);
    // state.mycart.splice(index, 1);
  }

  booking(data) {
    return axios
      .post(SalesOrders, data, { headers: authHeader() })
      .then((response) => {
        var data = response.data.data;

        return data;
      });
  }

  getDataCustomers(tenant_id) {
    return axios.get(Customers + "?tenant_id=" + tenant_id, {
      headers: authHeader(),
    });
  }

  addDataCustomer(data, tenant_id) {
    return axios
      .post(
        Customers,
        {
          tenant_id: tenant_id,
          code: data.code,
          name: data.name,
          email: data.email,
          type: data.type,
          customer_relation: data.customer_relation,
        },
        { headers: authHeader() }
      )
      .then((response) => {
        var data = response.data.data;

        return data;
      });
  }

  getDataTrademarks(tenant_id) {
    return axios.get(Trademark + "?tenant_id=" + tenant_id, {
      headers: authHeader(),
    });
  }

  getDataReferrals(tenant_id) {
    return axios.get(Referral + "?tenant_id=" + tenant_id, {
      headers: authHeader(),
    });
  }

  getDataEmployees(tenant_id) {
    return axios.get(Employee + "?tenant_id=" + tenant_id, {
      headers: authHeader(),
    });
  }

  getDataAgents(tenant_id) {
    return axios.get(Agent + "?tenant_id=" + tenant_id, {
      headers: authHeader(),
    });
  }

  getDataComplimentaries(tenant_id) {
    return axios.get(Complimentary + "?tenant_id=" + tenant_id, {
      headers: authHeader(),
    });
  }

  getDataComplimentaryProduct(tenant_id, product_id) {
    return axios.get(
      Complimentary + "?tenant_id=" + tenant_id + "&product_id=" + product_id,
      { headers: authHeader() }
    );
  }

  getDataCustomerComplimentary(id, product_id, started_at, term) {
    return axios.get(
      Customers +
        "/" +
        id +
        "/complimentaries" +
        "?product_id=" +
        product_id +
        "&started_at=" +
        started_at +
        "&term=" +
        term,
      { headers: authHeader() }
    );
  }

  getDataBooking(customer_id) {
    return axios.get(SalesOrders + "?customer_id=" + customer_id, {
      headers: authHeader(),
    });
  }

  getDataInvoice(
    customer_id = "",
    payment_status = "",
    issued_at = "",
    contact_id,
    tenant_id
  ) {
    return axios.get(
      Invoices +
        "?tenant_id=" +
        tenant_id +
        "&contact_id=" +
        contact_id +
        "&customer_id=" +
        customer_id +
        "&payment_status=" +
        payment_status +
        "&issued_at=" +
        issued_at,
      { headers: authHeader() }
    );
  }

  getDataInvoiceDetail(id) {
    return axios.get(Invoices + "/" + id, { headers: authHeader() });
  }

  getDataBookingDetail(id) {
    return axios.get(SalesOrders + "/" + id, { headers: authHeader() });
  }

  invoice(data, tenant_id) {
    return axios
      .post(
        Invoices,
        {
          tenant_id: tenant_id, //rewuired
          location_id: data.location_id,
          bank_account_id: data.bank_account_id, //rewuired
          customer_id: data.customer_id,
          contact_id: data.contact_id,
          vendor_id: data.vendor_id,
          agent_id: data.agent_id,
          trademark_id: data.trademark_id, //rewuired
          code: data.code, //rewuired
          is_proforma: data.is_proforma, //rewuired
          name: data.name,
          sender_name: data.sender_name,
          sender_phone: data.sender_phone,
          sender_email: data.sender_email,
          sender_address: data.sender_address,
          receipent_name: data.receipent_name,
          receipent_phone: data.receipent_phone,
          receipent_email: data.receipent_email,
          receipent_address: data.receipent_address,
          issued_at: data.issued_at, //rewuired
          due_at: data.due_at, //rewuired
          currency_code: data.currency_code, //rewuired
          total_price: data.total_price, //rewuired
          total_discount: data.total_discount, //rewuired
          total_tax: data.total_tax, //rewuired
          total_paid: data.total_paid, //rewuired
          desc: data.desc,
          type: data.type, //rewuired
          status: data.status, //rewuired
          timezone: data.timezone,
          invoice_details: [], //rewuired
        },
        { headers: authHeader() }
      )
      .then((response) => {
        var data = response.data.data;

        return data;
      });
  }

  getDataBankAccounts(tenant_id) {
    return axios.get(BankAccounts + "?tenant_id=" + tenant_id, {
      headers: authHeader(),
    });
  }

  getDataCustomerTransaction(customer_id) {
    return axios.get(
      Customers + "/" + customer_id + "/sales_order_details?type=charged",
      { headers: authHeader() }
    );
  }

  getDataServiceUsage(tenant_id) {
    return axios.get(ServiceUsage + "?tenant_id=" + tenant_id, {
      headers: authHeader(),
    });
  }

  getAvailRoom(productId, locationId, startedAt, endedAt, term, tenantId) {
    return axios.get(
      Product +
        "/" +
        productId +
        "/get_available_room/" +
        "?location_id=" +
        locationId +
        "&started_at=" +
        startedAt +
        "&ended_at=" +
        endedAt +
        "&term=" +
        term +
        "&tenant_id=" +
        tenantId,
      { headers: authHeader() }
    );
  }

  getPromotionCode(code, customerId, productId, lengthOfTerm, qty) {
    return axios.get(
      Promotion +
        "?code=" +
        code +
        "&customer_id=" +
        customerId +
        "&product_id=" +
        productId +
        "&length_of_term=" +
        lengthOfTerm +
        "&quantity=" +
        qty,
      { headers: authHeader() }
    );
  }

  getEvent(tenant_id) {
    return axios.get(Events + "?tenant_id=" + tenant_id + "&is_active=Y", {
      headers: authHeader(),
    });
  }

  getWebContent(tenant_id, used_for_page) {
    return axios.get(
      WebContent +
        "?tenant_id=" +
        tenant_id +
        "&used_for_page=" +
        used_for_page +
        "&order_by=id&sort_by=desc",
      {
        headers: authHeader(),
      }
    );
  }

  getWebContentGetByCode(tenant_id, code) {
    return axios.get(
      WebContentGetByCode + "?tenant_id=" + tenant_id + "&code=" + code,
      {
        headers: authHeader(),
      }
    );
  }

  getWebContentWithPaginate(tenant_id, used_for_page,page,totalPage) {
    return axios.get(
      WebContent +
        "?tenant_id=" +
        tenant_id +
        "&used_for_page=" +
        used_for_page +
        "&order_by=id&sort_by=desc"
        + '&page=' + page + '&total_per_page='+totalPage,
      {
        headers: authHeader(),
      }
    );
  }

  getWebContentById(id) {
    return axios.get(
      WebContent +
       '/'+id,
      {
        headers: authHeader(),
      }
    );
  }
}

export default new ProductService();
